import Axios from 'axios'
import { API_URL, GUID } from '@/common/config'

export default class baseService {
  constructor () {
    Axios.defaults.headers.common['Access-Control-Allow-Origin'] = API_URL
  }

  static async fakedraw (token) {
    return await Axios.get(API_URL + '/api/game/fakedraw', { headers: { Authorization: 'Bearer ' + token } })
  }

  //static async draw (token) {
  //  return await Axios.get(API_URL + '/api/game/draw?guid=' + GUID, { headers: { Authorization: 'Bearer ' + token } })
  //}

  //static async recurringpayments (token) {
  // return await Axios.get(API_URL + '/api/game/recurringpayments?guid=' + GUID, { headers: { Authorization: 'Bearer ' + token } })
  //}  

  static async retryrecurringpayments (token) {
    return await Axios.get(API_URL + '/api/game/retryrecurringpayments?guid=' + GUID, { headers: { Authorization: 'Bearer ' + token } })
   }  

   static async publicPrizePot () {
    return await Axios.get(API_URL + '/api/game/publicprizepot', null, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
  }

  static async privatePrizePot (token) {
    return await Axios.get(API_URL + '/api/game/privateprizepot', { headers: { Authorization: 'Bearer ' + token } })
  }

  static async getAllHeroResults (basketId = 0) {
    return await Axios.get(API_URL + `/api/game/getallheroresults?basketId=${basketId}`, { headers: { } })
  }

  static async confirmPin (token, pin) {
    return await Axios.get(API_URL + '/api/game/confirmpin?pin=' + pin, { headers: { Authorization: 'Bearer ' + token } })
  }

  static async clubSignUp (newClub) {
    return await Axios.post(API_URL + '/api/game/clubsignup', newClub, { headers: { } })
  }

  static async getDrawsThisMonth () {
    return await Axios.get(API_URL + '/api/game/getdrawsthismonth', { headers: { } })
  }

  static async stats (token) {
    return await Axios.get(API_URL + '/api/game/stats', { headers: { Authorization: 'Bearer ' + token } })
  }

  static async gotAnyHeroes (token) {
    return await Axios.get(API_URL + '/api/game/gotanyheroes', { headers: { Authorization: 'Bearer ' + token } })
  }  

  static async playerTransactionsDump (token) {
    return Axios.get(API_URL + '/api/game/playertransactionsdump', { headers: { Authorization: 'Bearer ' + token } })
  }

  static async drawsDump (token) {
    return Axios.get(API_URL + '/api/game/drawsdump', { headers: { Authorization: 'Bearer ' + token } })
  }

  static async ticketTransactionsDump (token) {
    return Axios.get(API_URL + '/api/game/tickettransactionsdump', { headers: { Authorization: 'Bearer ' + token } })
  }

  static async recurringPaymentsDump (token) {
    return Axios.get(API_URL + '/api/game/recurringpaymentsdump', { headers: { Authorization: 'Bearer ' + token } })
  }
  
  static async clubDump (token) {
    return Axios.get(API_URL + '/api/game/clubdump', { headers: { Authorization: 'Bearer ' + token } })
  }

  static async clubRevenuesDump (token) {
    return Axios.get(API_URL + '/api/game/clubrevenuesdump', { headers: { Authorization: 'Bearer ' + token } })
  }

  static async signUpUsersDump (token) {
    return Axios.get(API_URL + '/api/game/signupusersdump', { headers: { Authorization: 'Bearer ' + token } })
  }

  static async failedPaymentsDump (token) {
    return Axios.get(API_URL + '/api/game/failedpaymentsdump', { headers: { Authorization: 'Bearer ' + token } })
  }  

  static async getLeaderboardDump (token) {
    return Axios.get(API_URL + '/api/game/getleaderboarddump', { headers: { Authorization: 'Bearer ' + token } })
  }  

  static async getLeaderboardWinnersDump (token) {
    return Axios.get(API_URL + '/api/game/getleaderboardwinnersdump', { headers: { Authorization: 'Bearer ' + token } })
  }  

  static async clubRevenueSummaryDump (token) {
    return Axios.get(API_URL + '/api/game/clubrevenuesummarydump', { headers: { Authorization: 'Bearer ' + token } })
  }  

  static async clubEntriesSummaryDump (token) {
    return Axios.get(API_URL + '/api/game/clubentriessummarydump', { headers: { Authorization: 'Bearer ' + token } })
  }  

  static async getClubLeaderBoard (cid, mth, yer) {
    return Axios.get(API_URL + '/api/game/getclubleaderboard?cid=' + cid + '&mth=' + mth + '&yer=' + yer, { headers: { } })
  }    

  static async doesClubUrlExists (clubUrl) {
    return Axios.get(API_URL + '/api/game/doesClubUrlExist?clubUrl=' + clubUrl, { headers: { } })
  }    
  
  static async generateHeroImage (token) {
    return Axios.get(API_URL + '/api/game/generateheroimage', { headers: { Authorization: 'Bearer ' + token } })
  }  

  static async weeklyResultsTweet (token) {
    return Axios.get(API_URL + '/api/game/weeklyresultstweet?guid=safc17021967ftm1990jty638x', { headers: { Authorization: 'Bearer ' + token } })
  }  

  static async dailyPromotionalTweet (token) {
    return Axios.get(API_URL + '/api/game/dailypromotionaltweet?guid=safc17021967ftm1990jty638x&', { headers: { Authorization: 'Bearer ' + token } })
  }    

  static async updateLeaderBoard (token) {
    return Axios.get(API_URL + '/api/game/updateleaderboard', { headers: { Authorization: 'Bearer ' + token } })
  }  
    
  //static async setLeaderBoard (token) {
    //return Axios.get(API_URL + '/api/game/setleaderboard', { headers: { Authorization: 'Bearer ' + token } })
  //}

  static async adminLeaderboardReconciliation (mth, yer, token) {
    return Axios.get(API_URL + '/api/game/adminleaderboardreconciliation?mth=' + mth + '&yer=' + yer, { headers: { Authorization: 'Bearer ' + token } })
  }
  
  static async setUserAsPaid (month, year, prize, totalPrize, playerId, basketId, token) {
    return Axios.post(API_URL + '/api/game/setuseraspaid?mth=' + month + '&yer=' + year + '&prz=' + prize + '&tot=' + totalPrize + '&uid=' + playerId + '&bsk=' + basketId, {}, { headers: { Authorization: 'Bearer ' + token } })
  }

  static async saveBoost (amount, percentage, token) {
    return Axios.get(API_URL + '/api/game/saveboost?amount=' + amount + '&percentage=' + percentage, { headers: { Authorization: 'Bearer ' + token } })
  } 
  
  static async getBoost (token) {
    return Axios.get(API_URL + '/api/game/getboost', { headers: { Authorization: 'Bearer ' + token } })
  } 

}
