<template>
  <mdb-modal
    v-if="!publicPage"
    id="orientationModal"
    class="usePortrait"
    style="background-color: black;"
  >
    <mdb-modal-body
      :class="{ 'modalHeight': mobileView }"
      class="orientationPadding"
      style="background-color: black;"
    >
      <div style="margin-top:0%;width:100%;">
        <img
          src="./../../assets/logo.png"
          class="imgWidth"
          style="display: block;margin: 0px auto;padding: 10px;border-radius: 5px;"
        >
      </div>
      <div class="primary-colour text-center largerFont" style="font-size:1rem;text-align:center;margin-top:10px;">
        Please rotate your device! If this does not work, then your device may be incompatible! Please therefore use a different and compatible device.
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OrientationModal',
  data () {
    return {
      publicPage: false
    }
  },
  computed: {
    ...mapGetters([
      'mobileView'
    ])
  },
  created() {
    if (window.location.href.indexOf('public') > -1) {
      this.publicPage = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.imgWidth {
  width:70%;
}
</style>
