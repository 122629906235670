<template>
  <div>
    <NavBar
      v-if="!hideNavbar"
      @processModal="processModal = true"
    />
    <RouteTransition />
    <FullScreenCardPreview />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NavBar from '@/components/navbar/navbar.vue'
import FullScreenCardPreview from '@/components/cardPreviews/fullScreenCardPreview.vue'
import RouteTransition from '@/components/UI/routeTransition.vue'

export default {
  name: 'Main',
  components: { NavBar, FullScreenCardPreview, RouteTransition },
  computed: {
    ...mapGetters(['clubUrl']),
    hideNavbar () {
      return (this.clubUrl != null && this.$route.path === '/' + this.clubUrl) || this.$route.path === '/public/countdown' || this.$route.path === '/public/all_results' || this.$route.path === '/public/last_result' ||  this.$route.path === '/leaderboard' ||  this.$route.path === '/public/unsubscribe'
    }
  },
  mounted () {
  },
}
</script>
