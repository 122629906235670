import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

const persist = new VuexPersist({
  key: 'GrassRootsLotteryApp',
  storage: window.localStorage
})
Vue.use(Vuex)

var isMobile = false
if (/mobile/i.test(navigator.userAgent)) {
  isMobile = true
  if (/ipad|tablet/i.test(navigator.userAgent)) {
    isMobile = false
  }
}

export default new Vuex.Store({
  state: {
    userData: {
      aut: false,
      lbl: []
    },
    loginData: true,
    rootData: true,
    safari: false,
    mobileView: isMobile,
    id: 0,
    hero: 0,
    sport: 0,
    colour: '',
    number: 0,
    firstInitial: '',
    secondInitial: '',
    club: { ClubId: 0 },
    currentBasket: [],
    mobilePreviewShowing: false,
    panelViewedOnMobile: 'Left',
    genericErrorModal: {
      errorTitle: '',
      errorMessage: '',
      showing: false
    },
    genericConfirmModal: {
      showing: false,
      confirmTitle: '',
      confirmMessage: '',
      confirmButtonMessage: '',
      confirmButtonMessage2: '',
      confirmButtonFunction: () => { return 'emptyanon' },
      confirmButtonFunction2: () => { return 'emptyanon' }
    },
    nextDrawDate: '',
    clubUrl: '',
    randomising: false,
  },
  mutations: {
    storeNextDrawDate (state, date) {
      state.nextDrawDate = date
    },
    storePanelViewedOnMobile (state, view) {
      state.panelViewedOnMobile = view
    },
    storeUserData (state, userData) {
      state.userData = userData
    },
    storeLoginData (state, loginData) {
      state.loginData = loginData
    },
    storeRootData (state, rootData) {
      state.rootData = rootData
    },
    storeMobileView (state, mobileView) {
      state.mobileView = mobileView
    },
    storeSafari (state, safari) {
      state.safari = safari
    },
    storeHero (state, hero) {
      state.hero = hero
    },
    storeId (state, id) {
      state.id = id
    },
    storeSport (state, sport) {
      state.sport = sport
    },
    storeColour (state, colour) {
      state.colour = colour
    },
    storeNumber (state, number) {
      state.number = number
    },
    storeFirstInitial (state, initial) {
      state.firstInitial = initial
    },
    storeSecondInitial (state, initial) {
      state.secondInitial = initial
    },
    storeClub (state, club) {
      state.club = club
    },
    storeClubUrl (state, clubUrl) {
      state.clubUrl = clubUrl
    },
    addCardToBasket (state, card) {
      if (card.Colour === 1) { card.Colour = '#ff0000' } // red
      if (card.Colour === 2) { card.Colour = '#0000ff' } // blue
      if (card.Colour === 3) { card.Colour = '#ffff00' } // yellow
      if (card.Colour === 4) { card.Colour = '#008000' } // green
      if (card.Colour === 5) { card.Colour = '#ff9900' } // orange
      if (card.Colour === 6) { card.Colour = '#800080' } // purple
      if (card.Colour === 7) { card.Colour = '#fa42d2' } // pink
      if (card.Colour === 8) { card.Colour = '#ffffff' } // white

      if (card.FirstInitial === 1) { card.FirstInitial = 'A' } // 1
      if (card.FirstInitial === 2) { card.FirstInitial = 'B' } // 2
      if (card.FirstInitial === 3) { card.FirstInitial = 'C' } // 3
      if (card.FirstInitial === 4) { card.FirstInitial = 'D' } // 4
      if (card.FirstInitial === 5) { card.FirstInitial = 'E' } // 5
      if (card.FirstInitial === 6) { card.FirstInitial = 'F' } // 6
      if (card.FirstInitial === 7) { card.FirstInitial = 'G' } // 7
      if (card.FirstInitial === 8) { card.FirstInitial = 'H' } // 8
      if (card.FirstInitial === 9) { card.FirstInitial = 'I' } // 9
      if (card.FirstInitial === 10) { card.FirstInitial = 'J' } // 10
      if (card.FirstInitial === 11) { card.FirstInitial = 'K' } // 11
      if (card.FirstInitial === 12) { card.FirstInitial = 'L' } // 12
      if (card.FirstInitial === 13) { card.FirstInitial = 'M' } // 13
      if (card.FirstInitial === 14) { card.FirstInitial = 'N' } // 14
      if (card.FirstInitial === 15) { card.FirstInitial = 'O' } // 15
      if (card.FirstInitial === 16) { card.FirstInitial = 'P' } // 16
      if (card.FirstInitial === 17) { card.FirstInitial = 'Q' } // 17
      if (card.FirstInitial === 18) { card.FirstInitial = 'R' } // 18
      if (card.FirstInitial === 19) { card.FirstInitial = 'S' } // 19
      if (card.FirstInitial === 20) { card.FirstInitial = 'T' } // 20
      if (card.FirstInitial === 21) { card.FirstInitial = 'U' } // 21
      if (card.FirstInitial === 22) { card.FirstInitial = 'V' } // 22
      if (card.FirstInitial === 23) { card.FirstInitial = 'W' } // 23
      if (card.FirstInitial === 24) { card.FirstInitial = 'X' } // 24
      if (card.FirstInitial === 25) { card.FirstInitial = 'Y' } // 25
      if (card.FirstInitial === 26) { card.FirstInitial = 'Z' } // 26

      if (card.SecondInitial === 1) { card.SecondInitial = 'A' } // 1
      if (card.SecondInitial === 2) { card.SecondInitial = 'B' } // 2
      if (card.SecondInitial === 3) { card.SecondInitial = 'C' } // 3
      if (card.SecondInitial === 4) { card.SecondInitial = 'D' } // 4
      if (card.SecondInitial === 5) { card.SecondInitial = 'E' } // 5
      if (card.SecondInitial === 6) { card.SecondInitial = 'F' } // 6
      if (card.SecondInitial === 7) { card.SecondInitial = 'G' } // 7
      if (card.SecondInitial === 8) { card.SecondInitial = 'H' } // 8
      if (card.SecondInitial === 9) { card.SecondInitial = 'I' } // 9
      if (card.SecondInitial === 10) { card.SecondInitial = 'J' } // 10
      if (card.SecondInitial === 11) { card.SecondInitial = 'K' } // 11
      if (card.SecondInitial === 12) { card.SecondInitial = 'L' } // 12
      if (card.SecondInitial === 13) { card.SecondInitial = 'M' } // 13
      if (card.SecondInitial === 14) { card.SecondInitial = 'N' } // 14
      if (card.SecondInitial === 15) { card.SecondInitial = 'O' } // 15
      if (card.SecondInitial === 16) { card.SecondInitial = 'P' } // 16
      if (card.SecondInitial === 17) { card.SecondInitial = 'Q' } // 17
      if (card.SecondInitial === 18) { card.SecondInitial = 'R' } // 18
      if (card.SecondInitial === 19) { card.SecondInitial = 'S' } // 19
      if (card.SecondInitial === 20) { card.SecondInitial = 'T' } // 20
      if (card.SecondInitial === 21) { card.SecondInitial = 'U' } // 21
      if (card.SecondInitial === 22) { card.SecondInitial = 'V' } // 22
      if (card.SecondInitial === 23) { card.SecondInitial = 'W' } // 23
      if (card.SecondInitial === 24) { card.SecondInitial = 'X' } // 24
      if (card.SecondInitial === 25) { card.SecondInitial = 'Y' } // 25
      if (card.SecondInitial === 26) { card.SecondInitial = 'Z' } // 26

      state.currentBasket.splice(0, 0, card)
    },
    setBasket (state, basket) {
      state.currentBasket = basket
    },
    addBackendBasketToExisting (state, basket) {
      state.currentBasket = [...basket, ...state.currentBasket]
    },
    removeItemFromBasket (state, index) {
      state.currentBasket.splice(index, 1)
    },
    clearBasket (state) {
      state.currentBasket = []
    },
    setMobilePreviewShowing (state, bool) {
      state.mobilePreviewShowing = bool
    },
    randomising (state, bool) {
      state.randomising = bool
    },
    GENERIC_ERROR_MODAL: (state, payload) => {
      if (payload.showing !== undefined) {
        state.genericErrorModal.showing = payload.showing
      } else {
        state.genericErrorModal.showing = false
      }
      if (payload.errorTitle !== undefined) {
        state.genericErrorModal.errorTitle = payload.errorTitle
      } else {
        state.genericErrorModal.errorTitle = ''
      }
      if (payload.errorMessage !== undefined) {
        state.genericErrorModal.errorMessage = payload.errorMessage
      } else {
        state.genericErrorModal.errorMessage = ''
      }
    },
    CLOSE_GENERIC_ERROR_MODAL: (state) => {
      state.genericErrorModal =
      {
        errorTitle: '',
        errorMessage: '',
        showing: false
      }
    },
    GENERIC_CONFIRM_MODAL: (state, payload) => {
      if (payload.showing !== undefined) {
        state.genericConfirmModal.showing = payload.showing
      } else {
        state.genericConfirmModal.showing = false
      }
      if (payload.confirmTitle !== undefined) {
        state.genericConfirmModal.confirmTitle = payload.confirmTitle
      } else {
        state.genericConfirmModal.confirmTitle = ''
      }
      if (payload.confirmMessage !== undefined) {
        state.genericConfirmModal.confirmMessage = payload.confirmMessage
      } else {
        state.genericConfirmModal.confirmMessage = ''
      }
      if (payload.confirmButtonMessage !== undefined) {
        state.genericConfirmModal.confirmButtonMessage = payload.confirmButtonMessage
      } else {
        state.genericConfirmModal.confirmButtonMessage = ''
      }
      if (payload.confirmButtonFunction !== undefined) {
        state.genericConfirmModal.confirmButtonFunction = payload.confirmButtonFunction
      } else {
        state.genericConfirmModal.confirmButtonFunction = () => { return 'emptyanon' }
      }
      if (payload.confirmButtonMessage2 !== undefined) {
        state.genericConfirmModal.confirmButtonMessage2 = payload.confirmButtonMessage2
      } else {
        state.genericConfirmModal.confirmButtonMessage2 = ''
      }
      if (payload.confirmButtonFunction2 !== undefined) {
        state.genericConfirmModal.confirmButtonFunction2 = payload.confirmButtonFunction2
      } else {
        state.genericConfirmModal.confirmButtonFunction2 = () => { return 'emptyanon' }
      }
    },
    CLOSE_GENERIC_CONFIRM_MODAL: (state) => {
      state.genericConfirmModal =
      {
        showing: false,
        confirmTitle: '',
        confirmMessage: '',
        confirmButtonMessage: '',
        confirmButtonMessage2: '',
        confirmButtonFunction: () => { return 'emptyanon' },
        confirmButtonFunction2: () => { return 'emptyanon' }
      }
    }
  },
  actions: {
    storeUserData (context) {
      context.commit('storeUserData')
    },
    storeLoginData (context) {
      context.commit('storeLoginData')
    },
    storeRootData (context) {
      context.commit('storeRootData')
    },
    storeMobileView (context) {
      context.commit('storeMobileView')
    },
    storeSafari (context) {
      context.commit('storeSafari')
    },
    storeClubUrl (context) {
      context.commit('storeClubUrl')
    }
  },
  modules: {
  },
  getters: {
    userData: state => state.userData,
    loginData: state => state.loginData,
    rootData: state => state.rootData,
    mobileView: state => state.mobileView,
    safari: state => state.safari,
    id: state => state.id,
    hero: state => state.hero,
    sport: state => state.sport,
    colour: state => state.colour,
    number: state => state.number,
    firstInitial: state => state.firstInitial,
    secondInitial: state => state.secondInitial,
    club: state => state.club,
    clubUrl: state => state.clubUrl,
    currentBasket: state => state.currentBasket,
    mobilePreviewShowing: state => state.mobilePreviewShowing,
    panelViewedOnMobile: state => state.panelViewedOnMobile,
    genericErrorModal: state => state.genericErrorModal,
    genericConfirmModal: state => state.genericConfirmModal,
    nextDrawDate: state => state.nextDrawDate,
    randomising: state => state.randomising
  },
  plugins: [persist.plugin]
})
