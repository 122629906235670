<template>
  <div
    style="position:fixed;top:0;left:0;padding:35px;width:100dvw;background: var(--bg-color)"
    class="mobilePreview"
    :class="{'mobilePreviewShowingFade': mobilePreviewShowing, 'dvh': safari === false, 'noDvh': safari }"
  >
    <PerspectiveContainer>
      <div
        style="height:100%;"
        class="d-flex align-items-center justify-content-center"
      >
        <CardPreviewFromStore
          font-size="4rem"
          icon-font-size="5rem"
          width="300"
        />
        <div class="previewButtons">
          <mdb-btn
            v-if="!randomising && secondInitial"
            class="btn primary-btn btn-radius btn-md"
            style="font-size:0.8rem;width:155px"
            @click="addToBasket()"
          >
            ADD TO BASKET
          </mdb-btn>
          <RandomiseHeroButton />
          <mdb-btn
            v-if="!randomising"
            class="btn btn-outline btn-radius btn-md"
            style="font-size:0.8rem;width:155px"
            @click="hidePreview()"
          >
            CLOSE
          </mdb-btn>
        </div>
      </div>
    </PerspectiveContainer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CardPreviewFromStore from '@/components/cardPreviews/cardPreviewFromStore.vue'
import PerspectiveContainer from '@/components/UI/perspectiveContainer.vue'
import RandomiseHeroButton from '@/components/playSections/randomiseHeroButton.vue'
import basketService from '@/api-services/basket.service'

export default {
  name: 'FullScreenCardPreview',
  components: {
    PerspectiveContainer, CardPreviewFromStore, RandomiseHeroButton
  },
  data () {
    return {
      addedToBasket: false
    }
  },
  computed: {
    ...mapGetters([
      'mobilePreviewShowing',
      'safari',
      'mobileView',
      'randomising',
      'userData',
      'hero',
      'sport',
      'colour',
      'number',
      'firstInitial',
      'secondInitial'
    ])
  },
  methods: {
    hidePreview () {
      this.resetBasket()
      this.$store.commit('setMobilePreviewShowing', false)
      if (!this.mobileView) {
        this.$router.push({path: '/play', query: { page: 1 }}).catch(() => {})
      }
      this.addedToBasket = false
    },
    async addToBasket () {
      const newCard = {
        id: 0,
        Hero: this.hero,
        Sport: this.sport,
        Colour: this.colour,
        Number: this.number,
        FirstInitial: this.firstInitial,
        SecondInitial: this.secondInitial,
        ClubId: 0
      }
      this.addedToBasket = true
      if (this.userData.aut) {
        this.loading = true
        const basketId = await basketService.addToBasket(newCard, this.userData.access_token)
        this.$store.commit('addCardToBasket', { ...newCard, BasketOrdersId: basketId.data })
        this.resetBasket()
        if (this.mobileView) {
          this.$store.commit('storePanelViewedOnMobile', 'Left')
        } else {
          this.$store.commit('storePanelViewedOnMobile', 'Right')
        }
        this.$router.push('/basket/checkout').catch(() => {})

      } else {
        // any card without a "BasketOrdersId" key will be added later to the basket backend once a player signs in
        this.resetBasket()
        this.$store.commit('addCardToBasket', newCard)
        if (this.mobileView) {
          if (this.userData.aut) {
            this.$router.push('/basket/checkout').catch(() => {})
          } else {
            this.$router.push('/basket/auth').catch(() => {})
          }
          this.$store.commit('storePanelViewedOnMobile', 'Left')
        } else {
          this.$store.commit('storePanelViewedOnMobile', 'Right')
          this.$router.push({path: '/play', query: { page: 1 }}).catch(() => {})
        }
      }
    },
    resetBasket () { 
      this.$store.commit('storeHero', 0)
      this.$store.commit('storeSport', 0)
      this.$store.commit('storeColour', '#e1e1e1')
      this.$store.commit('storeNumber', 0)
      this.$store.commit('storeFirstInitial', '')
      this.$store.commit('storeSecondInitial', '')
    },
  }
}
</script>

<style lang="scss" scoped>
.mobilePreview {
  z-index: -10;
  opacity:0;
  transition: 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobilePreview.mobilePreviewShowingFade {
  z-index: 100000;
  opacity:1;
  transition: .5s ease;
}
.previewButtons {
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem !important;
  position: absolute;
  bottom: 20%;
  z-index: 10;
}
.previewButtons i {
  margin: 3px 0 0 2px;
}
.dvh {
  height: 100dvh;
}
.noDvh {
  height: 100vh;
}
</style>
