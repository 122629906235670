<template>
  <mdb-btn
    v-if="!randomising"
    title="Spin to reveal your random hero"
    class="btn btn-radius btn-sm btn-md"
    :class="!mobilePreviewShowing ? 'btn-primary' : 'btn-outline'"
    :disabled="randomising == true"
    style="font-size:0.8rem;width:155px;"
    @click="randomise()"
  >
    <span
      class="d-flex align-items-center justify-content-center"
    >
      <div class="mr-2 d-flex align-items">
        <div>
          <i class="fa fa-dice" style="scale: 1.2;transform: translateY(-1px);margin-right: 6px;" />
        </div>
        <AnimatedWord
          class="d-none d-md-inline"
          :word="mobilePreviewShowing ? 'SPIN' : 'LUCKY SPIN'"
        />
        <AnimatedWord
          class="d-md-none"
          :word="'SPIN'"
        />
      </div>
    </span>
  </mdb-btn>
</template>

<script>
import { mapGetters } from 'vuex'
import AnimatedWord from "@/components/UI/bouncingWordWrapper.vue"

export default {
  name: 'RandomiseHeroButton',
  components: { AnimatedWord },
  computed: {
    ...mapGetters(['randomising', 'mobileView', 'mobilePreviewShowing'])
  },
  methods: {
    randomise () {
      this.$parent.$parent.addedToBasket = false
      this.$store.commit('randomising', true)
      this.$store.commit('setMobilePreviewShowing', true)
      this.$store.commit('storeHero', 0)
      this.$store.commit('storeSport', 0)
      this.$store.commit('storeColour', '#e1e1e1')
      this.$store.commit('storeNumber', 0)
      this.$store.commit('storeFirstInitial', '')
      this.$store.commit('storeSecondInitial', '')
      setTimeout(() => {
        this.$emit('randomise')
        this.$store.commit('randomising', false)
        this.chooseRandomOptions()
      }, 2500)
    },
    chooseRandomOptions () {
      // HERO
      const hero = Math.floor(Math.random() * 8) + 1
      this.$store.commit('storeHero', hero)

      /// SPORT
      const sport = Math.floor(Math.random() * 9) + 1
      this.$store.commit('storeSport', sport)

      // COLOUR
      const colourOptions = [
        { text: 'RED', hex: '#ff0000', value: 1 },
        { text: 'BLUE', hex: '#0000ff', value: 2 },
        { text: 'YELLOW', hex: '#ffff00', value: 3 },
        { text: 'GREEN', hex: '#008000', value: 4 },
        { text: 'ORANGE', hex: '#ff9900', value: 5 },
        { text: 'PURPLE', hex: '#800080', value: 6 },
        { text: 'PINK', hex: '#fa42d2', value: 7 },
        { text: 'WHITE', hex: '#999999', value: 8 }
      ]
      const colour = Math.floor(Math.random() * 8) + 1
      let option = colourOptions.find(v => v.value == colour)
      if (option.hex === '#999999') option.hex = '#ffffff'
      this.$store.commit('storeColour', option.hex)

      // NUMBER
      const number = Math.floor(Math.random() * 8) + 1
      this.$store.commit('storeNumber', number)

      // INITIALS
      const initialOptions = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      const firstIndex = Math.floor(Math.random() * 26)
      const secondIndex = Math.floor(Math.random() * 26)
      this.$store.commit('storeFirstInitial', initialOptions[firstIndex])
      this.$store.commit('storeSecondInitial', initialOptions[secondIndex])
    }
  }
}
</script>
